import { Link } from "react-router-dom";
import s from "./projectCards.module.scss";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import Aos from "aos";
import { useEffect } from "react";

export const ProjectCards = ({ cards }) => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section className={s.wr}>
      <ul className={s.grid}>
        {cards.map((card, i) => (
          <Card key={i} data={card} />
        ))}
      </ul>
    </section>
  );
};

const Card = ({ data }) => {
  return (
    <Link data-aos="fade-up" to={"/projects/" + data.slug} className={s.card}>
      <li>
        <div className={s.card_image__wr}>
          <img src={data.card_img_src} alt={data.name} />
        </div>
        <ul className={s.card_tags}>
          {data.categories.map((category, i) => (
            <li key={i}>{category.name}</li>
          ))}
        </ul>
        <ReactMarkdown className={s.card_title}>
          {"## " + data.name}
        </ReactMarkdown>
      </li>
    </Link>
  );
};
