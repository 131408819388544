import React from 'react';
import s from "./block.module.scss";
import {ReactMarkdown} from "react-markdown/lib/react-markdown";
import Video from "../Video/Video";

const Blocks = ({blocks}) => {
  return (
    <>
      {blocks.map((block, index) => (
        block.type === 'text' ? (
          <div className={s.block} data-aos="fade-right" key={index + block.type}>
            <ReactMarkdown className={s.Mark3}>
              {block.text}
            </ReactMarkdown>
          </div>
        ) : block.type === 'video_src' ? (
          <div data-aos="zoom-in-up" key={index + block.type}>
            <Video videoSrc={block.video_src} />
          </div>
        ) : block.type === 'img_src' ? (
          <div data-aos="zoom-in-up" key={index + block.type}>
            <img
              src={block.img_src}
              alt={block.type}
            />
          </div>
        ) : (
          <></>
        )
      ))}
    </>
  );
};

export default Blocks;