import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import s from "./ProjectsPage.module.scss";
import {Helmet} from "react-helmet";
import {useGetAlCategories, useGetAllProjects} from "../../shared/query";
import Category from "../../shared/components/ProjectCards/Category";
import {ProjectCards} from "../../shared/components/ProjectCards/ProjectCards";

export const ProjectsPage = () => {
  const {t} = useTranslation();
  const [categories, setCategories] = useState(null);
  const {data: categoriesData, loading: categoriesLoading, error: categoriesError} = useGetAlCategories();
  const {data: projectsData, loading: projectsLoading, error: projectsError} = useGetAllProjects(categories);
  //console.log('ff ',projectsData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet
        title="PushKeen - Проекты"
        meta={[{
          name: "description",
          content: "Проекты компании PushKeen",
        }]}
      />
      <div className="container">
        <section className={s.projects_page}>
          <h2 className={s.title}>{t("projects")}</h2>

          {categoriesLoading ? (
            <>Загрузка</>
          ) : categoriesError ? (
            <>Ошибка</>
          ) : (
            <Category
              categoriesData={categoriesData}
              categories={categories}
              setCategories={setCategories}
            />
          )}

          {projectsLoading ? (
            <>Загрузка</>
          ) : projectsError ? (
            <>Ошибка</>
          ) : (
            <ProjectCards cards={projectsData} />
          )}

        </section>
      </div>
    </>
  );
};
