import React from 'react';
import s from "./projectCards.module.scss";

const Category = ({categoriesData, categories, setCategories}) => {
  return (
    <ul className={s.filter}>
      <button
        className={categories ? "" : s.filter_button__active}
        onClick={() => setCategories("")}
      >
        <h4>Все</h4>
      </button>
      {categoriesData.map((category, i) => (
        <button
          key={i}
          onClick={() => setCategories(category.id)}
          className={
            categories === category.id ? s.filter_button__active : ""
          }
        >
          <h4>{category.name}</h4>
        </button>
      ))}
    </ul>
  );
};

export default Category;