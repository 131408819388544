import React, {useEffect} from 'react';
import {useGetProject} from "../../shared/query";
import {Helmet} from "react-helmet";
import s from "./Hilkov/Hilkov.module.scss";
import {Mainscreen} from "../../shared/components/Mainscreen/Mainscreen";
import {useMediaQuery} from "@mui/material";
import {breakpoints} from "../../styles/variables";
import Aos from "aos";
import Blocks from "../../shared/components/ProjectPage/Blocks";

const ProjectContent = ({slug}) => {
  const {data, loading, error} = useGetProject(slug)
  console.log(data);

  const isMobile = useMediaQuery(breakpoints.mobile);

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Helmet
        title={loading ? "..." : error ? "ошибка" : data.name}
        meta={[{name: "description", content: data?.annotation}]}
      />
      <section className={s.hydrocosmos}>
      {loading ? (
        <>
          loading
        </>
      ) : error ? (
        <>
          error
        </>
      ) : (
        <>
          <Mainscreen
            path={isMobile ? data.banner_mobile : data.banner_desktop}
          />
          <div className="container">
            <div className={s.about}>
              <div className={s.about_left}>
                <div className={s.about_left_title} data-aos="fade-right">
                  <h2 className={s.about_left_title_name}>
                    {data.name}
                  </h2>
                  <span className={s.about_left_title_label}>
                    {data.annotation}
                  </span>
                </div>
                <div className={s.buttons}>
                  {data.categories.map((item, index) => (
                    <div key={index} className={s.game}>{item.name}</div>
                  ))}
                </div>
                <div className={s.task_date} data-aos="fade-left">
                  <h3 className={s.number}>{data.time}</h3>
                  <h5 className={s.month}>мес</h5>
                  <p className={s.label}>Срок реализации проекта</p>
                </div>
              </div>

              <div className={s.about_content} data-aos="fade-left">
                <h3 className={s.about_content_name}>О проекте:</h3>
                <span className={s.about_content_label}>
                  {data.description}
                </span>
              </div>
            </div>
            <Blocks blocks={data.blocks}/>
          </div>
        </>
      )}
      </section>
    </>
  );
};

export default ProjectContent;