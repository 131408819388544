import {useEffect, useState} from "react";
import axios, {AxiosRequestConfig} from "axios";

const BASE_URL = "https://admin.pushkeen.ru/"
const CONFIG: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: '*/*',
  },
};

export const useGetAllProjects = (category: number | null) => {

  const [result, setResult] = useState<{
    loading: boolean;
    data: null | any;
    error: Error | null;
  }>({
    loading: true,
    data: null,
    error: null,
  });

  useEffect(() => {
    const fetchProjectsList = async () => {
      try {
        const response = await axios.get(BASE_URL + `projects?category_id=${category ? category : ''}`, CONFIG);
        setResult({
          loading: false,
          data: response.data,
          error: null,
        });
      } catch (error) {
        setResult({
          loading: false,
          data: null,
          error: error as Error,
        });
      }
    };

    fetchProjectsList();
  }, [category]);
  return result;
};

export const useSlugs = () => {
  const [result, setResult] = useState<{
    loading: boolean;
    data: null | any;
    error: Error | null;
  }>({
    loading: true,
    data: null,
    error: null,
  });

  useEffect(() => {
    const fetchProjectsList = async () => {
      try {
        const response = await axios.get(BASE_URL + `projects/slugs`, CONFIG);
        setResult({
          loading: false,
          data: response.data,
          error: null,
        });
      } catch (error) {
        setResult({
          loading: false,
          data: null,
          error: error as Error,
        });
      }
    };

    fetchProjectsList();
  }, []);
  return result;
};

export const useGetAlCategories = () => {
  const [result, setResult] = useState<{
    loading: boolean;
    data: null | any;
    error: Error | null;
  }>({
    loading: true,
    data: null,
    error: null,
  });

  useEffect(() => {
    const fetchProjectsList = async () => {
      try {
        const response = await axios.get(BASE_URL + 'categories', CONFIG);
        setResult({
          loading: false,
          data: response.data,
          error: null,
        });
      } catch (error) {
        setResult({
          loading: false,
          data: null,
          error: error as Error,
        });
      }
    };

    fetchProjectsList();
  }, []);
  return result;
};

export const useGetProject = (slug: number | null) => {

  const [result, setResult] = useState<{
    loading: boolean;
    data: null | any;
    error: Error | null;
  }>({
    loading: true,
    data: null,
    error: null
  });

  useEffect(() => {
    const fetchProjectsList = async () => {
      try {
        const response = await axios.get(BASE_URL + `projects?slug=` + slug, CONFIG);
        setResult({
          loading: false,
          data: response.data,
          error: null,
        });
      } catch (error) {
        setResult({
          loading: false,
          data: null,
          error: error as Error,
        });
      }
    };

    fetchProjectsList();
  }, [slug]);
  return result;
};